import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/layout'
import Banner from '../components/banner'
import Seo from '../components/seo'

import TeamSeoImage from '../assets/images/team.jpg'

class Team extends React.Component {
    render() {
        return (
            <StaticQuery
            query={graphql`
                query TeamMeta {
                    site {
                        siteMetadata {
                            title
                            description
                            emailUrl
                            phone
                            google {
                                googleMapAPIKey
                                googleReCaptchaKey
                            }
                            team {
                                simone {
                                    name
                                    emailUrl
                                }
                                larry {
                                    name
                                    emailUrl
                                }
                            }
                        }
                    }
                }
            `}
            render={data => (
                <>
                <Layout page='team'>
                    <Seo title={`Meet the Team | ${data.site.siteMetadata.title}`} description={`With over two decades of combined legal experience in this field of law, we swiftly and efficiently navigate through the municipal, county and state agency land development approval process faced by developers.`} image={TeamSeoImage} keywords={[``]} />
                    <Banner title='Meet the Team' subTitle='' titleColor='' subTitleColor='' image={TeamSeoImage} />
                    <section id='team'>
                        <Container>
                            <Row>
                                <Col id='larry' className='center-flex panel team-container' xs='12' sm='12' md='12' lg='12'>
                                    {/* <Col xs='12' sm='4' md='4' lg='4'>
                                        <img className='img-responsive' src='//via.placeholder.com/350x400' alt='Simone Calli, Esq.' />
                                    </Col> */}
                                    <Col xs='12' sm='8' md='8' lg='8'>
                                        <h5>{data.site.siteMetadata.team.larry.name}</h5>
                                        <p><strong>Prior Experience:</strong></p>
                                        <ul>
                                            <li>Porzio, Bromberg &amp; Newman, P.C. (Principal) 2016-2017; (Counsel) 2012-2016; (Associate) 2007-2012</li>
                                            <li>Price, Meese, Schulman &amp; D'Arminio, P.C. (Associate) 2006-2007</li>
                                            <li>Beattie, Padovano, LLC (Associate), 2005-2006</li>
                                            <li>The Hon. Brian R. Martinotti, J.S.C., Civil Div., Bergen County (Law Clerk), 2004-2005</li>
                                            <li>Herten, Burstein, Sheridan, Cevasco, Bottinelli, Litt &amp; Harz, LLC (Law Clerk), 2003-2005</li>
                                        </ul>
                                        <p><strong>Organizations/Memberships:</strong></p>
                                        <ul>
                                            <li>Real Professionals Network</li>
                                            <li>Certified Mediator of Special Civil Matters of the Superior Court</li>
                                            <li>Bergen County Bar Association, Young Lawyer's Division - (President) 2010; (President Elect) 2009; (Vice President) 2008; (Treasurer) 2007-2008</li>
                                            <li>Judge of the Bergen County Mock Trial Competition, 2008-2010</li>
                                            <li>Member of the Morris Pashman Inns of Court, 2008-2009</li>
                                        </ul>
                                        <p><strong>Publications:</strong></p>
                                        <ul>
                                            <li>Co-Blogger of the Porzio Real Property Blog, 2010</li>
                                            <li>"Council on Affordable Housing Overview of the Adopted Third-Round Regulations", Porzio Update, June 2008</li>
                                            <li>"Redevelopment Landscape Appears Blighted", New Jersey Lawyer, September 21, 2007</li>
                                        </ul>
                                        <p><strong>Speaking Engagements:</strong></p>
                                        <ul>
                                            <li>"The Land Use Process:  The Contours and Hardships", Morris County Bar Assoc. CLE Seminar, December 4, 2013</li>
                                            <li>"Consumer Fraud Act and Construction Lien Law Update", NJ Electrical Contractors Assoc., December 2011</li>
                                            <li>Bergen County Judicial Law Clerk Orientation Program, September 2011</li>
                                            <li>"Construction Lien Law", NJ Plumbing Heating and Cooling Contractors Assoc., Atlantic City, NJ, June 2011</li>
                                            <li>Bergen County Judicial Law Clerk Orientation Program, September 2010</li>
                                            <li>"Safe Maneuvering Through the Pitfalls: Consumer Fraud Act Seminar", Community Builders Assoc., December 2009</li>
                                            <li>Permit Extension Act Workship, September 2008.</li>
                                        </ul>
                                        <p><strong>Bar Admissions:</strong></p>
                                        <ul>
                                            <li>New Jersey, Pennsylvania &amp; New York</li>
                                        </ul>
                                        <p><strong>Education:</strong></p>
                                        <ul>
                                            <li>St. John's University School of Law, J.D., 2004</li>
                                            <li>Fordham University, B.S., Psychology, 2001</li>
                                        </ul>
                                    </Col>
                                </Col>
                                <Col id='simone' className='center-flex panel team-container' xs='12' sm='12' md='12' lg='12'>
                                    {/* <Col xs='12' sm='4' md='4' lg='4'>
                                        <img className='img-responsive' src='//via.placeholder.com/350x400' alt='Larry Calli, Esq.' />
                                    </Col> */}
                                    <Col xs='12' sm='8' md='8' lg='8'>
                                        <h5>{data.site.siteMetadata.team.simone.name}</h5>
                                        <p><strong>Prior Experience:</strong></p>
                                        <ul>
                                            <li>The Law Offices of Stephen P. Sinisi, Esq. LLC (Of Counsel), 2012-2017</li>
                                            <li>Price, Meese, Shulman &amp; D'Arminio, PC (Associate), 2007-2012</li>
                                            <li>The Hon. Peter E. Doyne, P.J.S.C. (retired) Chancery Div, Bergen County (Law Clerk), 2006-2007</li>
                                        </ul>
                                        <p><strong>Organizations/Memberships:</strong></p>
                                        <ul>
                                            <li>Real Professionals Network</li>
                                            <li>Certified Mediator of Special Civil Matters of the Superior Court</li>
                                            <li>Bergen County Bar Association, Young Lawyer's Division (Officer), 2009-2012</li>
                                            <li>Judge of the Bergen County Mock Trial Competition, 2008-2010</li>
                                            <li>Graduate of the Morris Pashman Inn of Court, 2009</li>
                                        </ul>
                                        <p><strong>Court Appointments:</strong></p>
                                        <ul>
                                            <li>Condemnation Commissioner</li>
                                            <li>Court-appointed counsel in guardianship proceedings</li>
                                        </ul>
                                        <p><strong>Speaking Engagements:</strong></p>
                                        <ul>
                                            <li>Land Use Symposium of the Bergen County Bar Association, 2010</li>
                                        </ul>
                                        <p><strong>Bar Admissions:</strong></p>
                                        <ul>
                                            <li>New Jersey &amp; Pennsylvania</li>
                                        </ul> 
                                        <p><strong>Education:</strong></p>
                                        <ul>
                                            <li>Seton Hall University School of Law, J.D., 2006</li>
                                            <li>New York University, B.A., Politics, 2003</li>
                                        </ul>
                                    </Col>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </Layout>
                </>
            )}
            />
        )
    }
}

export default Team